import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Carousel } from 'primereact/carousel';
import { Image } from 'primereact/image';
import CheckIcon from "../../assets/icons/check-icon.svg";
import Icon1 from "../../assets/icons/home/icon01.svg";
import Icon2 from "../../assets/icons/home/icon02.svg";
import Icon4 from "../../assets/icons/programas/icon04.svg";
import Icon6 from "../../assets/icons/programas/icon06.svg";
import Icon7 from "../../assets/icons/programas/icon07.svg";
import Icon8 from "../../assets/icons/programas/icon08.svg";
import Icon9 from "../../assets/icons/programas/icon09.svg";
import Icon10 from "../../assets/icons/programas/icon10.svg";
import HeaderNav from '../../components/Header';
import Footer from '../../components/Footer';
import SEO from '../../components/SEO';
import { ButtonPrimary } from '../../components/Button';
import CarouselTestimoniosTemplate from '../components/CarouselTestimoniosTemplate';
import { getBannerPrograma, getProgramas } from '../../api/client/programas';
import { useCustomState } from '../../hooks/useCustomState';
import { getCarouselTestimoniosPospartoItems } from '../../misc/getCarouselItems';
import { responsiveOptionsCarousel } from '../../utils/responsiveOptionsCarousel';
import intersectionObserver from '../../utils/intersectionObserver';
import VideoPlayer from '../../components/Modal/VideoPlayer';
import EspecialistaCertificada from '../components/EspecialistaCertificada';
import PreguntasFrecuentes from '../components/PreguntasFrecuentes';
import EntrenaApp from './components/EntrenaApp';
import Membresias from './components/Membresias';
import EvaluacionPersonalizada from '../components/EvaluacionPersonalizada';
import GlobalContext from '../../contexts/GlobalContext';
import { getPreguntasFrecuentesPosparto } from '../../misc/getPreguntasFrecuentes';
import { setSubscription } from '../../redux/reducers/subscriptionSlice';

const ProgramaPosparto = () => {
  const { name } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const elementsRef = useRef([]);

  const { setModal } = useContext(GlobalContext);
  const [state, setState] = useCustomState({
    carouselTestimoniosItems: [],
    preguntasFrecuentes: [],
    isVideoVisible: false,
    portada_video_introduccion: null,
    video_introduccion: null,
    programaDetalles: null,
    bannerDetalles: null,
  });

  useEffect(() => {
    searchSuscripcionData();
    loadProgram();

    (async () => {
      setState({
        carouselTestimoniosItems: getCarouselTestimoniosPospartoItems(),
        preguntasFrecuentes: await getPreguntasFrecuentesPosparto(),
      });
    })();

    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  const searchSuscripcionData = useCallback(async () => {
    // const _getVimeoAPI = async (id) => (await getDataVimeoApi(id))?.data;

    setState({
      portada_video_introduccion: "/assets/images/posparto_fitmom.jpg", // (await _getVimeoAPI(821854458))?.video?.thumbs?.base,
      video_introduccion: {
        url: "/assets/videos/posparto_fitmom.mp4",
      },
    });
  }, [setState]);

  const loadProgram = useCallback(async () => {
    try {
      const programasResponse = await getProgramas({ code: name });
      const programDetails = programasResponse?.data?.[0];

      let bannerResponse = null;
      if (programDetails?.id) {
        try {
          bannerResponse = await getBannerPrograma({ programID: programDetails.id });
        } catch (bannerError) { }
      }

      setState({
        programaDetalles: programDetails,
        bannerDetalles: bannerResponse?.data || { image: require('../../assets/images/programas/banners/banner-posparto.jpg') },
      });
    } catch (error) {
      setState({
        bannerDetalles: { image: require('../../assets/images/programas/banners/banner-posparto.jpg') },
      });
    }
  }, [name, setState]);

  const seleccionarSuscripcion = useCallback((plan) => {
    dispatch(setSubscription({
      data: {
        id: state.programaDetalles?.id,
        tipo_programa: "Programa de entrenamiento",
        code: state.programaDetalles?.code,
        nombre: state.programaDetalles?.name,
        plan: plan,
        precio: plan === "mensual" ? state.programaDetalles?.cost_month : state.programaDetalles?.cost_biannual,
      }
    }));
    navigate(`/carrito/posparto-${plan}`);
  }, [state.programaDetalles, dispatch, navigate]);

  return (
    <>
      <SEO
        title="Programa de entrenamiento"
        description="Para mujeres de 0 a 12 meses postparto/postcesárea o mujeres que desean corregir disfunciones."
      />
      <VideoPlayer
        responsive
        url={state.video_introduccion?.url}
        visible={state.isVideoVisible}
        onHide={() => setState({ isVideoVisible: false })}
        propsVideo={{
          controls: true,
          controlsList: "nodownload"
        }}
      />
      <div className={`bg-gradient-to-tl from-primary to-secondary bg-cover bg-no-repeat bg-center`} style={{ backgroundImage: `url(${state.bannerDetalles?.image})`, paddingTop: "calc(720 / 1920 * 100%)" }}>
        <HeaderNav />
      </div>

      <div className='bg-Magnolia pb-10'>
        <div className="xl:container mx-auto px-6">
          <div className='grid grid-cols-1 items-center gap-14'>
            <div className='col-span-1 pt-10 divide-y divide-solid divide-Crayola/50 text-center lg:text-start'>
              <div className='mb-6'>
                <h1
                  ref={(el) => elementsRef.current.push(el)}
                  className='animate__animated font-golos-medium text-md md:text-xl lg:text-2xl text-Crayola'
                  data-animation={"animate__fadeInDown"}
                >
                  Programa de entrenamiento
                </h1>
                <h1
                  ref={(el) => elementsRef.current.push(el)}
                  className='animate__animated font-golos-semibold text-xl md:text-3xl lg:text-5xl text-DeepKoamaru'
                  data-animation={"animate__fadeInUp"}
                >
                  FITMOM POSPARTO
                </h1>
                <p
                  className='text-md text-DarkJungleGreen mt-4'
                >
                  Programa de ejercicios para la recuperación después del parto o la cesárea y ejercicios para corregir diástasis, incontinencia, vientre abultado (mommy pooch), dolor de espalda o dolor pélvico.
                </p>
              </div>
              <div className='pt-6'>
                <p
                  className='font-golos-semibold text-md md:text-xl lg:text-2xl text-Crayola'
                >
                  Ideal para:
                </p>
                <div className='grid md:grid-cols-2 gap-6 mt-6'>
                  <div className='flex justify-center lg:justify-start gap-3'>
                    <Image
                      src={CheckIcon}
                      imageClassName="mt-1 select-none pointer-events-none"
                      imageStyle={{ minWidth: 20, width: 20 }}
                    />
                    <p className='mb-0'>Rehabilitar y recuperar el cuerpo después del parto o la cesárea y regresar al ejercicio de forma segura.</p>
                  </div>
                  <div className='flex justify-center lg:justify-start gap-3'>
                    <Image
                      src={CheckIcon}
                      imageClassName="mt-1 select-none pointer-events-none"
                      imageStyle={{ minWidth: 20, width: 20 }}
                    />
                    <p className='mb-0'>Aplanar el abdomen, reducir cintura y definir abdominales.</p>
                  </div>
                  <div className='flex justify-center lg:justify-start gap-3'>
                    <Image
                      src={CheckIcon}
                      imageClassName="mt-1 select-none pointer-events-none"
                      imageStyle={{ minWidth: 20, width: 20 }}
                    />
                    <p className='mb-0'>Corregir diástasis, incontinencia, dolor de espalda baja, dolor pélvico o púbico.</p>
                  </div>
                  <div className='flex justify-center lg:justify-start gap-3'>
                    <Image
                      src={CheckIcon}
                      imageClassName="mt-1 select-none pointer-events-none"
                      imageStyle={{ minWidth: 20, width: 20 }}
                    />
                    <p className='mb-0'>Construir fuerza y masa muscular después del parto o la cesárea.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-span-1 flex items-center justify-center'>
              <div className='flex flex-wrap mx-auto items-center justify-center'>
                <div ref={(el) => elementsRef.current.push(el)} className='animate__animated relative flex items-center justify-center' data-animation={"animate__pulse"}>
                  <Image
                    src={state.portada_video_introduccion}
                    alt="Portada del video de introducción"
                    className='flex justify-center items-center mx-auto select-none pointer-events-none'
                    imageStyle={{ borderRadius: 25, width: "90%", maxWidth: 760 }}
                  />
                  <div className='absolute c-pointer active:scale-90 transition ease-in-out' onClick={() => setState({ isVideoVisible: true })}>
                    <Image src={require('../../assets/images/play-btn.png')} alt="icon play" imageClassName='mx-auto select-none pointer-events-none max-w-[46px] xs:max-w-[64px] sm:max-w-[80px]' imageStyle={{ width: "90%" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Membresias
        typeProgram={state.programaDetalles?.code}
        onSubscribe={seleccionarSuscripcion}
        options={{
          price_month: state.programaDetalles?.cost_month,
          price_biannual: state.programaDetalles?.cost_biannual,
        }}
      />

      <EntrenaApp />

      <div className='pt-10 pb-16 bg-white'>
        <div className="xl:container mx-auto px-6 max-w-5xl">
          <h1 ref={(el) => elementsRef.current.push(el)} className='font-golos-semibold text-Crayola text-center text-2xl mb-10 animate__animated' data-animation={"animate__fadeInDown"}>Contenidos del programa:</h1>
          <div className='flex flex-wrap gap-8 mb-16 text-center md:text-left'>
            <div className='lg:flex-1 md:max-w-[180px] w-full'>
              <p className='font-golos-semibold text-DeepKoamaru text-2xl'>El programa está dividido en 2 etapas:</p>
            </div>
            <div className='xs:flex-1'>
              <p className='text-DeepKoamaru'>
                <span className='font-golos-semibold'>ETAPA 1:</span> Durante 8 semanas trabajarás con ejercicios de suelo pélvico y core  para recuperar la fuerza y función de tu pared abdominal después del parto o la cesárea. Aprenderás ejercicios clave para corregir diástasis, incontinencia, dolor de espalda y ejercicios para aplanar tu abdomen de forma segura y efectiva. Esta etapa se puede iniciar tan pronto como el día 1 después del parto o la cesárea y te ayudará a favorecer tu recuperación y a salir del dolor más pronto.
              </p>
            </div>
            <div className='xs:flex-1 md:flex-[0.7]'>
              <p className='text-DeepKoamaru'>
                <span className='font-golos-semibold'>ETAPA 2:</span> Después de completar la etapa 1, avanzarás en fuerza y en intensidad con ejercicios funcionales de fuerza, cardio, yoga, pilates y barre de bajo impacto ideales para los primeros 6 meses posparto para progresivamente recuperar fuerza y masa muscular y prepararte para ejercicios de mayor intensidad.
              </p>
            </div>
            <p className='font-golos-semibold text-VividRed text-center'>
              Para garantizar los mejores resultados se recomienda seguir el programa por lo menos durante 6 meses y siguiendo el orden de las etapas sin combinar con nada externo.
            </p>
          </div>
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon1} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Ejercicios de recuperación después del parto o cesárea incluyendo ejercicios para fortalecer suelo pélvico y core.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon2} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Ejercicios para corregir diástasis, incontinencia y vientre abultado (mommy pooch).</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon10} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Guía de rutinas semanales con ejercicios de fuerza, cardio, yoga, pilates y barre postnatales. 200+ entrenamientos disponibles.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon4} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Rutinas cortas de ejercicios y rutinas para hacer con tu bebé.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon4} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Acceso a recursos de apoyo emocional en el posparto.</p>
            </div>
          </div>
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 mt-4'>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon1} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Técnicas de masaje para cicatrices de cesárea, episiotomía o desgarro.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon4} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Estiramientos para aliviar dolor de espalda baja, cuello, ciática y más.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon6} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Guía nutricional y recetas saludables.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon7} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Documentos con información para complementar tu recuperación.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon8} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Acceso a grupo privado de alumnas.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon9} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Acceso a la aplicación móvil.</p>
            </div>
          </div>
          <EspecialistaCertificada />
        </div>
      </div>

      <EvaluacionPersonalizada />

      <div className='py-12 bg-PinkLace'>
        <div className="xl:container mx-auto px-6">
          <h1 ref={(el) => elementsRef.current.push(el)} className='font-golos-semibold text-Crayola text-center text-2xl mt-4 mb-10 animate__animated' data-animation={"animate__slideInDown"}>Preguntas frecuentes sobre el programa</h1>
          <div>
            <PreguntasFrecuentes
              data={state.preguntasFrecuentes}
            />
          </div>
          <div className='flex items-center justify-center mt-12 mb-5'>
            <div className='bg-Crayola py-3 px-6 rounded-full inline-flex'>
              <p className='text-AntiFlashWhite text-center'>¿Te quedaron dudas?, escribe a <a href='mailto:hola@jimefitmom.com' className='font-golos-semibold text-none'>hola@jimefitmom.com</a></p>
            </div>
          </div>
        </div>
      </div>

      <div className='pt-0 md:pt-4 pb-12 bg-white'>
        <div className="xl:container mx-auto px-6">
          <div className='max-w-[90%] flex flex-wrap items-center justify-center sm:justify-between mx-auto gap-4 mt-8 mb-10'>
            <div className='text-center md:text-left'>
              <h1 ref={(el) => elementsRef.current.push(el)} className='font-golos-semibold text-Crayola text-2xl mb-0 animate__animated' data-animation={"animate__slideInLeft"}>Testimonios de clientas felices</h1>
              <p>Comparten el progreso en 8 semanas de haber iniciado el programa</p>
            </div>
            <div>
              <ButtonPrimary
                text="¡Envía tu testimonio!"
                withIcon
                styleContainer={{
                  minWidth: 200
                }}
                onClick={() => setModal("testimonio", navigate)}
              />
            </div>
          </div>

          <Carousel
            value={state.carouselTestimoniosItems}
            numVisible={4}
            numScroll={4}
            responsiveOptions={responsiveOptionsCarousel}
            className="custom-carousel pagination-indicators-gray"
            circular
            showNavigators={false}
            autoplayInterval={7000}
            itemTemplate={(item) => CarouselTestimoniosTemplate(item, { heightClassName: "h-[24rem]" })}
          />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ProgramaPosparto;