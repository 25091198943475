import React, { memo, useContext, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Badge } from 'primereact/badge';
import { Sidebar } from 'primereact/sidebar';
import classNames from "classnames";
import { ReactComponent as BarsIcon } from '../assets/icons/bars-icon.svg';
import { ReactComponent as DropdownIcon } from '../assets/icons/dropdown-icon.svg';
import { ReactComponent as CarritoIcon } from '../assets/icons/carrito-icon.svg';
import GlobalContext from "../contexts/GlobalContext";
import { useCustomState } from "../hooks/useCustomState";
import getPageHome from "../utils/getPageHome";
import { isEmpty } from "../utils";

import { fillSVG, navItems } from "./utils";
import Promocion from "./Promocion";

const HeaderNav = ({ theme }) => {
  const { setModal } = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [bgNav, setBgNav] = useState("bg-[#ffffff00]")
  const [state, setState] = useCustomState({
    showSubMenu: false,
    isClickedSubMenu: false,
    isMenuMobileVisible: false,
  });
  const { user } = useSelector(state => state.app.auth);
  const { data: suscripcion_seleccionada } = useSelector(state => state.app.subscription);
  const { isAuthenticated } = useSelector(state => state.app.auth);

  const { stylesActiveDropDown, classNameIsActiveMenu, IconDropDown, suscripcion_seleccionada_nombre } = useMemo(() => ({
    stylesActiveDropDown: {
      background: theme === "white" ? "#7e73d6" : "#fff",
      border: theme === "white" ? "1px solid #7e73d6" : "1px solid #eee",
      borderBottom: 0,
      color: theme === "white" ? "#fff" : "#191e25",
      padding: "0.5em 1.5em",
      paddingRight: "0.5em",
      borderRadius: "6px 6px 0 0",
    },
    classNameIsActiveMenu: (location, pathname) => (location?.pathname?.indexOf(pathname) !== -1 ? "font-golos-semibold" : "font-golos"),
    IconDropDown: (_theme) => <DropdownIcon className="ml-1" fill={fillSVG(_theme, state)} />,
    suscripcion_seleccionada_nombre: !isEmpty(suscripcion_seleccionada?.nombre) ? (suscripcion_seleccionada?.nombre?.replace("Fitmom", "")?.trim() || "")?.toLowerCase() : null,
  }), [theme, state, suscripcion_seleccionada?.nombre]);

  const classNameText = classNames({
    "fill-Crayola": theme === "white" ? true : false,
    "fill-white": theme !== "white" ? true : false,
    "text-Crayola": theme === "white" ? true : false,
    "text-white": theme !== "white" ? true : false,
    "hover:text-Crayola": theme !== "white" ? true : false,
    "hover:text-DeepKoamaru": theme === "white" ? true : false,
  });

  document.addEventListener("scroll", (event) => {
    if (window.scrollY > 250) {
      setBgNav("bg-[#8682a1]")
    } else {
      setBgNav("bg-[#ffffff00]")
    }
  })

  return (
    <>
      <Promocion />
      <div className="fixed left-[50%] top-8 z-20 flex justify-center items-center">
        <div className={`${theme === "white" ? "bg-white" : ""} ${getPageHome() === "/mi-cuenta" ? "hidden md:block" : ""} fixed z-10 mx-auto px-6 py-4 justify-between items-center select-none w-full ${bgNav}`}>
          <div className="flex justify-between items-center animate__animated animate__fadeIn gap-6 w-11/12 mx-auto">
            <div className="flex items-center">
              <Link to={getPageHome()}>
                <img src={require(theme === "white" ? "../assets/images/logo2.png" : "../assets/images/logo.png")} alt="Logo" className="w-full select-none pointer-events-none" style={{ maxWidth: 140 }} height={46} width={140} />
              </Link>
            </div>
            <div className="inline-flex lg:hidden">
              <div className="c-pointer active:scale-90 transition ease-in-out" onClick={() => setState({ isMenuMobileVisible: true })}>
                <BarsIcon fill={theme === "white" ? "#7e73d6" : "#fff"} height={28} />
              </div>
            </div>
            <Sidebar
              visible={state.isMenuMobileVisible}
              onHide={() => setState({ isMenuMobileVisible: false })}
              className="menu-close-padding-custom"
            >
              <div className="flex justify-center items-center mb-4">
                <Link to={getPageHome()}>
                  <img src={require("../assets/images/logo2.png")} alt="Logo" className="w-full select-none pointer-events-none" style={{ maxWidth: 160, margin: "auto" }} height={46} width={160} />
                </Link>
              </div>
              <ul className="flex flex-col items-center justify-center">
                {navItems.map((item, index) => (
                  <li key={`nav-item-${index}`} className="select-none">
                    {item.subItems ? (
                      <div
                        className={`py-4 relative z-50 c-pointer`}
                        onClick={() => setState({ isClickedSubMenu: !(state.isClickedSubMenu) })}
                      >
                        <div className={`${classNameIsActiveMenu(location, item.path)} flex items-center !text-Crayola fill-Crayola hover:text-Crayola transition duration-300`} style={(state.isClickedSubMenu || state.showSubMenu) ? stylesActiveDropDown : {}}>
                          {item.label} <DropdownIcon className="pl-1 ml-auto" fill={"#7e73d6"} />
                        </div>
                        <div className={`w-full relative z-50 transition duration-300 ${(state.isClickedSubMenu || state.showSubMenu) ? 'block opacity-100 pointer-events-auto' : 'hidden opacity-0 pointer-events-none'}`}>
                          <ul
                            className={`bg-white divide-y divide-gris-EEEEEE text-center`}
                            style={{
                              border: "1.5px solid #F5F5F5",
                              borderRadius: "0 0 6px 6px",
                              marginTop: 0,
                              left: 0,
                              minWidth: 180,
                            }}
                          >
                            {item.subItems?.filter(subItem => subItem?.label !== user?.Program?.name)?.map((subItem, subIndex) => (
                              <li key={`nav-sub-item-${subIndex}`} className="block px-4 py-2 text-Crayola">
                                <Link to={subItem.path}>{subItem.label}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <Link
                        to={item.path}
                        className={`${classNameIsActiveMenu(location, item.path)} block py-4 font-medium text-Crayola fill-Crayola hover:text-Crayola transition duration-300`}
                      >
                        {item.label}
                      </Link>
                    )}
                  </li>
                ))}
                <li>
                  <div
                    className={`${classNameIsActiveMenu(location, "contacto")} py-4 font-medium text-Crayola fill-Crayola hover:text-Crayola transition duration-300 c-pointer pointer-events-auto select-none`}
                    onClick={() => setModal("contacto")}
                  >
                    Contacto
                  </div>
                </li>
                <li>
                  {isAuthenticated ? (
                    <Link
                      to="/mi-cuenta"
                      className="block my-4 font-medium text-sm text-white"
                    >
                      <span className="block font-semibold border border-Crayola bg-Crayola hover:border-[#6b60c7] hover:bg-[#6b60c7] px-4 py-2 transition duration-200" style={{ borderRadius: 20 }}>Mi Cuenta</span>
                    </Link>
                  ) : (
                    <div
                      className={`py-4 font-medium text-sm text-Crayola fill-Crayola hover:text-Crayola transition duration-300 c-pointer pointer-events-auto select-none`}
                      onClick={() => setModal("login", navigate)}
                    >
                      <span className="font-semibold border hover:bg-white px-4 py-2 transition duration-100" style={{ borderRadius: 20 }}>Iniciar Sesión</span>
                    </div>
                  )}
                </li>
                <li>
                  <Link
                    to={(!isEmpty(suscripcion_seleccionada_nombre) && !isEmpty(suscripcion_seleccionada?.plan)) ? `/carrito/${suscripcion_seleccionada_nombre}-${suscripcion_seleccionada?.plan}` : "/carrito"}
                    className={`flex py-4 font-medium text-Crayola fill-Crayola hover:text-Crayola transition duration-300`}
                  >
                    <i className="block p-overlay-badge">
                      <CarritoIcon className={`w-5 text-Crayola fill-Crayola hover:text-Crayola`} />
                      {suscripcion_seleccionada?.id && (<Badge severity="danger" />)}
                    </i>
                  </Link>
                </li>
              </ul>
            </Sidebar>
            <nav className="hidden lg:flex items-center">
              <ul className="flex items-center">
                {navItems.filter(item => !item.onlyMobile).map((item, index) => (
                  <li key={`nav-item-${index}`}>
                    {item.subItems ? (
                      <div
                        className={`px-4 relative z-50 c-pointer`}
                        onClick={() => setState({ isClickedSubMenu: !(state.isClickedSubMenu) })}
                        onMouseEnter={() => { setState({ showSubMenu: true }) }}
                        onMouseLeave={() => { setState({ showSubMenu: false }) }}
                      >
                        <div className={`${classNameIsActiveMenu(location, item.path)} flex items-center ${classNameText} transition duration-300`} style={(state.isClickedSubMenu || state.showSubMenu) ? stylesActiveDropDown : {}}>
                          {item.label} {IconDropDown(theme)}
                        </div>
                        <div className={`w-full absolute z-50 transition duration-300 ${(state.isClickedSubMenu || state.showSubMenu) ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
                          <ul
                            className={`bg-white divide-y divide-gris-EEEEEE text-center`}
                            style={{
                              border: "1.5px solid #F5F5F5",
                              borderRadius: "0 0 6px 6px",
                              marginTop: 0,
                              left: 0,
                              minWidth: 180,
                            }}
                          >
                            {item.subItems?.filter(subItem => subItem?.label !== user?.Program?.name)?.map((subItem, subIndex) => (
                              <li key={`nav-sub-item-${subIndex}`} className="px-4 py-2 hover:text-Crayola">
                                <Link to={subItem.path}>{subItem.label}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <Link
                        to={item.path}
                        className={`${classNameIsActiveMenu(location, item.path)} mx-4 font-medium ${classNameText} transition duration-300`}
                      >
                        {item.label}
                      </Link>
                    )}
                  </li>
                ))}
                <div
                  className={`${classNameIsActiveMenu(location, "contacto")} mx-4 font-medium ${classNameText} transition duration-300 c-pointer`}
                  onClick={() => setModal("contacto")}
                >
                  Contacto
                </div>
                {isAuthenticated ? (
                  <Link
                    to="/mi-cuenta"
                    className="mx-4 font-medium text-sm text-white"
                  >
                    <span className="font-semibold border border-Crayola bg-Crayola hover:border-[#6b60c7] hover:bg-[#6b60c7] px-4 py-2 transition duration-200" style={{ borderRadius: 20 }}>Mi Cuenta</span>
                  </Link>
                ) : (
                  <div
                    className={`mx-4 font-medium text-sm ${classNameText} transition duration-300 c-pointer`}
                    onClick={() => setModal("login", navigate)}
                  >
                    <span className="font-semibold border hover:bg-white px-4 py-1.5 transition duration-100" style={{ borderRadius: 20 }}>Iniciar Sesión</span>
                  </div>
                )}
                <Link
                  to={(!isEmpty(suscripcion_seleccionada_nombre) && !isEmpty(suscripcion_seleccionada?.plan)) ? `/carrito/${suscripcion_seleccionada_nombre}-${suscripcion_seleccionada?.plan}` : "/carrito"}
                  className={`relative mx-4 font-medium ${classNameText} transition duration-300`}
                >
                  <i className="block p-overlay-badge">
                    <CarritoIcon className={`h-5 ${classNameText}`} />
                    {suscripcion_seleccionada?.id && (<Badge severity="danger" />)}
                  </i>
                </Link>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export const HeaderSimple = memo(({ bgNav, isLogoWhite }) => {
  return (
    <div className={`mx-auto px-6 py-4 justify-between items-center select-none w-full ${bgNav || "bg-white"}`}>
      <div className="flex justify-center items-center animate__animated animate__fadeIn gap-6 mx-auto">
        <div className="flex items-center">
          <img src={require(!isLogoWhite ? "../assets/images/logo2.png" : "../assets/images/logo.png")} alt="Logo" className="mx-auto w-full select-none pointer-events-none" style={{ maxWidth: 140 }} />
        </div>
      </div>
    </div>
  );
});

export default HeaderNav;