import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { useDebounce } from 'primereact/hooks';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { ReactComponent as SearchIcon } from "../assets/icons/search-icon.svg";
import HeaderNav from '../components/Header';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import SEO from '../components/SEO';
import { getPosts } from '../api/client/blog';
import { colors } from '../config/colors';
import { useCustomState } from '../hooks/useCustomState';
import { isEmpty } from '../utils';
import { dayjs_custom } from '../utils/dayjs_custom';

const headerImage = require('../assets/images/jimeblog-header.jpg');

const BlogSingle = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const [state, setState] = useCustomState({
    loading: true,
    title: null,
    description: null,
    content: null,
    post: null,
  });
  const [searchInput, , setSearchInput] = useDebounce('', 1500);

  useEffect(() => {
    loadData(slug);
    // eslint-disable-next-line
  }, [slug]);

  const loadData = useCallback(async (slug) => {
    const posts = await getPosts({ slug });
    const post = posts?.data?.[0];

    const htmlContent = new QuillDeltaToHtmlConverter(JSON.parse(post?.content), {});

    setState({
      post: post,
      title: post?.name,
      description: post?.summary,
      content: htmlContent.convert(),
      loading: false
    });
  }, [setState]);

  const onSearch = useCallback(() => {
    if (!isEmpty(searchInput)) navigate(`/blog?q=${searchInput}`);
  }, [searchInput, navigate]);

  return (
    <>
      <SEO
        title={!isEmpty(state.title) ? `${state.title} - Blog` : "Blog"}
        description={state.description || ""}
      />
      <div className={`bg-Crayola/40 bg-gradient-to-tl from-primary to-secondary bg-cover bg-center h-[13rem] pt-20`} style={{ backgroundImage: `url(${headerImage})` }}>
        <HeaderNav />
        <div className='xl:container mx-auto px-8 mt-8'>
          {/* <h2 className='font-golos-medium text-white text-xl -mb-1'>Lorem ipsum dolor sit</h2> */}
          <h1 className='font-golos-semibold text-white text-5xl'>BLOG</h1>
        </div>
      </div>

      <div className='bg-Magnolia pb-10 pt-10'>
        <div className="xl:container mx-auto px-6">
          <div className='flex flex-col sm:flex-row gap-10 w-full'>
            <div className='flex-1 order-2 sm:order-1'>
              {state.loading ? (
                <div className="w-full mt-8">
                  <Loading />
                </div>
              ) : (
                <div className='blog-single flex flex-col gap-8 bg-white rounded-xl px-5 py-6'>
                  <Image
                    src={state?.post?.image}
                    alt={state?.post?.name}
                    loading='lazy'
                    imageClassName="object-contain rounded-md"
                    width="100%"
                  />
                  <div className="flex flex-col flex-1 mt-1 w-full">
                    <h1 className="font-golos-medium text-Crayola text-2xl mb-2">{state?.post?.name}</h1>
                    <p className="flex-1 font-golos text-sm">Publicado {dayjs_custom(state?.post?.createdAt).format("DD/MM/YYYY")}</p>
                  </div>
                  <div style={{ wordBreak: "break-word", wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: state.content }} />
                </div>
              )}
            </div>
            <div className='sm:flex-[0.6] md:flex-[0.4] order-1 sm:order-2'>
              <div className="relative flex items-center w-full">
                <InputText
                  type='text'
                  className='py-4 pl-4 pr-12 text-sm border-0 rounded-xl w-full'
                  placeholder="Búsqueda en el blog"
                  onChange={event => setSearchInput(event.target.value)}
                  onKeyDown={event => {
                    if (event.key === 'Enter') { onSearch() }
                  }}
                />
                <div className='absolute right-0 pl-1 pr-3 c-pointer active:scale-90 transition ease-in-out' onClick={onSearch}>
                  <div className='p-2'>
                    <SearchIcon fill={colors.Orchid} height={14} width={14} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BlogSingle;